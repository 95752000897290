@use './config/' as *;
@forward './main-scss/';
@forward "./swiper/swiper-bundle";
body {
    &.webview {
        .waf-head {
            padding-top: 0;
            .title {
                @extend %d-block;
            }
        }
    }
}
.footer-top {
    @extend %d-none;
}
.waf-green-initiative {
    .overyear-swiper {
        height: var(--total-height, max-content);
    }
    @extend %mx-3-neg;
    .green-initiative-showcase {
        @extend %pt-5;
    }
    .layout-wrapper {
        max-width: unset;
    }
    .preview-swiper {
        .article {
            &-thumbnail {
                @extend %relative;
                &::after {
                    content: "";
                    background: linear-gradient(180deg, var(--neutral-1000), transparent);
                    @include position-combo(inset);
                    @extend %h-30;
                    @extend %w-100;
                    @extend %pointer-none;
                }
                &::before {
                    content: "";
                    background: linear-gradient(0deg, var(--neutral-1000) -25%, hsl(var(--hsl-neutral-1000) / 0));
                    z-index: var(--z-index1);
                    @extend %absolute;
                    @extend %h-100;
                    @extend %w-100;
                    @extend %d-block;
                    @extend %pointer-none;
                }
            }
            &-content {
                bottom: 0;
                -webkit-backdrop-filter: blur(0.2rem);
                backdrop-filter: blur(0.2rem);
                z-index: var(--z-showcase-content);
                margin-inline: auto;
                @extend %w-100;
                @extend %absolute;
                @extend %flex-wrap;
                @extend %pb-6;
                @extend %relative;
                @extend %neutral-50;
                @extend %flex-c-c;
                & > a {
                    flex-basis: 100%;
                }
            }
            &-title {
                margin-bottom: 0;
                @include truncate(2, 20, 1.2);
                @extend %font-20-pm;
                @extend %px-4;
                @extend %text-center;
            }
        }
        .img-box {
            img {
                object-fit: cover;
                object-position: top center;
            }
        }
    }
    .swiper {
        &-pagination {
            height: 0.4rem;
            bottom: var(--space-2);
            @extend %flex-c-n;
            @extend %gap-1;
            @extend %w-100;
            &.swiper-pagination-bullets .swiper-pagination-bullet {
                margin-inline: 0;
            }
            &-bullet {
                width: 1.6rem;
                transition: width 0.3s ease-in, background-color 0.3s ease-in;
                @extend %h-100;
                @extend %rounded-radius;
                @extend %neutral-50-bg;
                &-active {
                    width: 3.2rem;
                    background-color: var(--primary-500);
                }
            }
        }
        &-button {
            &-prev,
            &-next {
                @extend %d-none;
            }
        }
    }
    .igknighting-change-section {
        @extend %py-6;
        @extend %px-3;
        .thumbnail {
            @extend %mb-4;
        }
        .knighting-change-image {
            border-radius: var(--full-radius);
            @extend %w-100;
        }
        .title {
            @extend %font-18-pm;
            @extend %secondary-1000;
            @extend %mb-2;
        }
        .sub-title {
            @extend %mb-4;
        }
        .text {
            @extend %mb-4;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .core-initiatives-section {
        background: linear-gradient(0deg, var(--secondary-1000) 25.82%, var(--secondary-900) 113.23%);
        @extend %px-3;
        @extend %pb-6;
        @extend %mb-1;
        @extend %secondary-1100;
        @extend %relative;
        &::after {
            content: "";
            background-size: contain;
            @include position-combo(tl);
            @include background(null, "/static-assets/images/green-intiative/pattern.png", left/auto repeat-y);
            @extend %pointer-none;
            @extend %h-100;
            @extend %w-100;
        }
        .title {
            @extend %font-18-pm;
            @extend %neutral-50;
            @extend %py-6;
        }
        .article {
            &-list {
                gap: var(--space-3);
                @include listing-card(overlay);
                @extend %x-auto;
                @extend %flex-nowrap;
                @extend %flex;
            }
            &-item {
                border-radius: var(--full-radius);
                width: calc((100% - var(--space-4)) / 1.5);
                flex: 0 0 auto;
                @extend %hidden;
                @extend %relative;
            }
            &-content {
                z-index: var(--z-index2);
                @extend %flex;
                @extend %flex-wrap;
            }
            &-title {
                height: 6rem;
                @extend %w-100;
                @extend %font-14-pm;
                @extend %text-center;
                @extend %px-5;
                @extend %pb-4;
            }
            &-thumbnail {
                aspect-ratio: 3 / 4;
            }
        }
    }
    .overyear-section {
        @extend %secondary-1100;
        @extend %relative;
        .title {
            @extend %font-18-pm;
            @extend %secondary-1000;
            @extend %pt-6;
            @extend %pb-5;
            @extend %px-3;
        }
        .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
            @extend %d-none;
        }
        .overyear {
            &-image {
                aspect-ratio: 1/1;
                width: 4.8rem;
                height: 4.8rem;
            }
            &-wrap {
                @extend %flex;
                @extend %gap-3;
            }
            &-item {
                @extend %ml-4;
                &:before {
                    content: "";
                    aspect-ratio: 1 / 1;
                    width: 1rem;
                    height: 1rem;
                    border: .2rem solid clr(secondary-1000);
                    z-index: var(--z-index2);
                    @include position(2rem, null, null, -2rem);
                    @extend %circle-radius;
                }
                &:first-child,
                &:last-child {
                    &::before {
                        @extend %secondary-1000-bg;
                    }
                }
            }
            &-logo {
                @extend %relative;
                &:before {
                    content: "";
                    width: .1rem;
                    height: 1rem;
                    transform: rotate(90deg);
                    @include position(2rem, null, null, -.5rem);
                    @extend %secondary-200-bg;
                }
            }
            &-title {
                @extend %mt-2-neg;
                @extend %ml-6;
                @extend %pr-12;
                .title {
                    padding-top: 0;
                    padding-inline: 0;
                    font-size: 1.6rem;
                    font-weight: 500;
                    @extend %pb-3;
                }
            }
            &-description {
                @extend %ml-6;
                @extend %pr-12;
                .text {
                    font-size: 1.4rem;
                    font-weight: 400;
                    @extend %neutral-800;
                }
                .overyearlist {
                    list-style-type: disc;
                    @extend %pl-6;
                    @extend %ml-6-neg;
                    li {
                        &:last-child {
                            @extend %mb-0;
                        }
                        &::marker {
                            font-size: 1.5em;
                            line-height: 0.1;
                        }
                    }
                }
            }
            &-list {
                flex-direction: column;
                max-height: 55rem;
                @extend %ml-3;
                @extend %gap-6;
                &::before {
                    content: "";
                    width: .1rem;
                    border-left: .1rem solid clr(secondary-200);
                    @include position-combo(y-center, null, 0.5rem);
                    @extend %h-100;
                }
                &::after {
                    content: "";
                    bottom: -5.9rem;
                    left: -3rem;
                    width: calc(100% + var(--space-6));
                    background: linear-gradient(0deg, clr(secondary-100, 5) 0%, transparent 100%);
                    z-index: var(--z-index1);
                    height: 10rem;
                    @include position(null, null, -5.9rem, -3rem);
                }
                &.show {
                    max-height: unset;
                }
                &.hide {
                    max-height: 55rem;
                }
            }
        }
        .year {
            background: -webkit-linear-gradient(var(--secondary-1100), var(--secondary-100));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 3.2rem;
            font-weight: 700;
            opacity: 50%;
        }
        .show-more {
            width: max-content;
            @extend %py-2;
            @extend %px-5;
            @extend %flex-n-c;
            @extend %gap-1;
            @extend %primary-500-bg;
            @extend %font-12-pm;
            @extend %secondary-1000;
            @extend %uppercase;
            @extend %half-radius;
        }
        .button-action {
            @extend %mx-auto;
            z-index: var(--z-index2);
            @extend %flex-c-c;
            @extend %relative;
            @extend %w-100;
            @extend %mb-4;
        }
    }
    .impact-number {
        background: linear-gradient(0deg, var(--secondary-1000) 25.82%, var(--secondary-900) 113.23%);
        @extend %secondary-1100;
        @extend %px-3;
        @extend %pb-6;
        @extend %relative;
        &::after {
            content: "";
            @include background(null, "green-intiative/pattern.png", left / contain repeat-y);
            @include position-combo(tl);
            @extend %pointer-none;
            @extend %h-100;
            @extend %w-100;
        }
        .title {
            @extend %font-18-pm;
            @extend %neutral-50;
            @extend %py-6;
        }
        .impact {
            &-content {
                @extend %neutral-50;
            }
            &-wrapper {
                @extend %flex-c-c;
                @extend %flex-wrap;
                @extend %relative;
                &::before {
                    content: "";
                    height: 0.1rem;
                    width: calc(var(--container-max-width) - 25%);
                    bottom: var(--space-4);
                    @extend %pos-x-center;
                    @extend %secondary-800-bg;
                }
            }
            &-item {
                @extend %w-50;
                @extend %flex-c-c;
                @extend %relative;
                @extend %mb-8;
            }
            &-wrap {
                @extend %flex-c-c;
                @extend %gap-1;
            }
            &-image {
                width: 4.8rem;
                height: 4.8rem;
            }
        }
        .count {
            @extend %font-18-pm;
        }
        .subtext {
            @extend %font-14-pr;
        }
        .text {
            @extend %pr-2;
            @extend %font-12-pr;
            @extend %neutral-100;
            @extend %d-block;
        }
        .first,
        .third {
            .impact-item {
                &:nth-child(1) {
                    @extend %w-100;
                    &:before {
                        content: "";
                        height: 0.1rem;
                        width: calc(var(--container-max-width) - 25%);
                        bottom: var(--space-4-neg);
                        @extend %pos-x-center;
                        @extend %secondary-800-bg;
                    }
                }
                &:nth-child(2) {
                    &::after {
                        content: "";
                        height: 5rem;
                        width: 0.1rem;
                        right: 0;
                        @extend %absolute;
                        @extend %secondary-800-bg;
                    }
                }
                &:nth-child(3) {
                    @extend %pl-3;
                }
            }
        }
        .second {
            .impact-item {
                .impact-wrap {
                    align-items: center;
                    @extend %flex-column;
                    @extend %text-center;
                }
                &:nth-child(1) {
                    &::after {
                        content: "";
                        height: 5rem;
                        width: 0.1rem;
                        right: 0;
                        @extend %absolute;
                        @extend %secondary-800-bg;
                    }
                }
                &:nth-child(2) {
                    .text {
                        padding-right: 0;
                    }
                }
            }
            .impact-image {
                width: 8rem;
                height: 8rem;
            }
            .count {
                @extend %font-20-pm;
            }
        }
        .third {
            .subtext {
                @extend %d-none;
            }
        }
        .third.impact-wrapper {
            &::before {
                content: unset;
            }
            .impact-item {
                &:nth-child(2),
                &:nth-child(3) {
                    @extend %mb-6;
                }
            }
        }
        .records {
            @extend %neutral-50;
        }
    }
    .upcoming-event {
        @extend %pb-6;
        @extend %px-3;
        .title {
            @extend %font-18-pm;
            @extend %secondary-1000;
            @extend %py-6;
        }
        .article {
            &-list {
                gap: var(--space-3);
                @include listing-card(overlay);
                @extend %flex-column;
            }
            &-item {
                border-radius: var(--full-radius);
                @extend %hidden;
                @extend %w-100;
                @extend %relative;
                &::after {
                    content: "";
                    height: 1.5rem;
                    width: 0.1rem;
                    opacity: 0.3;
                    @extend %pos-y-center;
                    @extend %neutral-50-bg-5;
                    @extend %absolute;
                }
            }
            &-content {
                z-index: var(--z-index2);
                @extend %flex;
                @extend %flex-wrap;
            }
            &-title {
                margin: 0 auto;
                @extend %font-14-pm;
                @extend %text-center;
                @extend %px-6;
                @extend %pb-3;
            }
            &-thumbnail {
                aspect-ratio: 16 / 9;
                @extend %hidden;
            }
        }
        .event-image {
            transition: 1s ease;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
@include mq(col-md) {
    .waf-green-initiative {
        .swiper {
            &-pagination {
                height: 0.6rem;
            }
        }
        .preview-swiper {
            .article-title {
                font-size: 2.6rem;
                overflow: hidden;
                height: unset;
            }
        }
        .igknighting-change-section {
            pointer-events: none;
            @include background(null, "green-intiative/ig-knightning-bg.png", left / cover repeat-y);
            .title {
                font-size: 3.2rem;
            }
            .sub-title,
            .text {
                font-size: 1.6rem;
            }
        }
        .core-initiatives-section {
            .head-wrap {
                .title {
                    color: var(--neutral-50);
                    padding-block: var(--space-8);
                    font-size: 3.2rem;
                }
            }
            .article {
                &-title {
                    font-size: 1.6rem;
                }
            }
        }
        .impact-number {
            .title {
                color: var(--neutral-50);
                padding-block: var(--space-8);
                font-size: 3.2rem;
            }
            .count {
                font-size: 5rem;
            }
            .subtext {
                font-size: 1.4rem;
                padding-left: var(--space-2);
                margin-top: var(--space-1-neg);
                @include position-combo(y-center);
            }
            .text {
                display: block;
                padding-inline: 0;
                font-size: 1.4rem;
            }
            .second {
                .count {
                    font-size: 6rem;
                }
                .subtext {
                    margin-top: var(--space-7);
                }
            }
        }
        .upcoming-event {
            .title {
                font-size: 3.2rem;
                padding-block: var(--space-8);
            }
            .view-all {
                font-size: 1.4rem;
                padding: var(--space-2) var(--space-4);
            }
            .article {
                &-title {
                    font-size: 1.6rem;
                    padding-bottom: var(--space-4);
                }
            }
        }
        .overyear-section {
            &::before {
                content: "";
                background-size: contain;
                @include position-combo(tl);
                @include background(null, "patterns/overyear-top.png", top/contain no-repeat);
                pointer-events: none;
                width: 100%;
                height: 100%;
                opacity: 30%;
            }
            &::after {
                content: "";
                background-size: contain;
                @include position-combo(bl);
                @include background(null, "patterns/overyear-bottom.png", bottom/contain no-repeat);
                pointer-events: none;
                width: 100%;
                height: 100%;
            }
            .waf-body {
                position: relative;
            }
            .title {
                font-size: 3.2rem;
                padding-inline: 0;
                padding-bottom: 0;
            }
            .waf-head {
                padding-inline: var(--container-white-space);
            }
            .overyear {
                &-swiper {
                    position: static;
                    padding-inline: var(--container-white-space);
                }
                &-list {
                    gap: 0;
                    position: relative;
                    margin-left: unset;
                    max-height: unset;
                    @include flex-config(flex, row);
                    &::after,
                    &::before {
                        display: none;
                    }
                }
                &-item {
                    width: calc((100% - var(--space-20)) / 3);
                    align-self: flex-end;
                    padding-block: var(--space-4);
                    margin-left: unset;
                    height: var(--max-odd-height, max-content);
                    &::before {
                        content: "";
                        aspect-ratio: 1 / 1;
                        width: 1rem;
                        height: 1rem;
                        border: .2rem solid clr(secondary-1000);
                        border-radius: 50%;
                        z-index: 2;
                        @include position(-.5rem, null, null, 1.8rem);
                    }
                    &::after {
                        content: '';
                        border-top: .2rem solid clr(secondary-200);
                        width: 100%;
                        height: unset;
                        top: 0;
                        left: calc(-1* var(--container-white-space));
                        width: var(--window-inner-width);
                        position: absolute;
                    }
                    &:nth-child(even) {
                        align-self: flex-start;
                        display: flex;
                        padding-bottom: var(--space-4);
                        height: var(--max-even-height, max-content);
                        .overyear {
                            &-year {
                                .year {
                                    background: -webkit-linear-gradient(var(--secondary-100), var(--secondary-1100));
                                    -webkit-background-clip: text;
                                }
                            }
                            &-logo {
                                &::before {
                                    top: 1rem;
                                    transform: unset;
                                }
                            }
                            &-image {
                                @include position(unset, null, calc(100% - 9.5rem), -1.1rem);
                            }
                            &-wrap,
                            &-content {
                                margin-bottom: var(--space-3-neg);
                                margin-top: unset;
                                display: flex;
                                flex-direction: column-reverse;
                            }
                            &-title {
                                margin-bottom: var(--space-4-neg);
                                margin-top: 0;
                                padding-top: var(--space-3);
                                .title {
                                    padding-bottom: 0;
                                }
                            }
                        }
                        &::before {
                            @include position(unset, null, -.5rem, null);
                        }
                        &::after {
                            content: '';
                            border-top: .1rem solid clr(secondary-200);
                            width: 100%;
                            height: unset;
                            @include position(100%, null, null, 0);
                        }
                    }
                    &:last-child,
                    &:first-child {
                        &::before {
                            content: "";
                            border: none;
                            aspect-ratio: 1 / 1;
                            background: url(/static-assets/images/svg/live-blue.svg?v=#{$image-version}) center center no-repeat;
                            background-size: contain;
                            width: 3rem;
                            height: 3rem;
                            @include position(-1.5rem, null, null, 0.8rem);
                        }
                    }
                }
                &-logo {
                    width: 7rem;
                    &::before {
                        content: "";
                        width: .1rem;
                        height: 2rem;
                        background: var(--secondary-200);
                        transform: unset;
                        @include position(-3rem, null, null, 2.2rem);
                    }
                }
                &-wrap,
                &-content {
                    display: flex;
                    flex-direction: column;
                    margin-top: var(--space-3-neg);
                }
                &-image {
                    aspect-ratio: 1 / 1;
                    width: 7rem;
                    height: 7rem;
                    @include position(calc(100% - 9.5rem), null, null, -1.1rem);
                }
                &-title {
                    margin-top: var(--space-4-neg);
                    margin-left: unset;
                    padding-bottom: unset;
                }
                &-description {
                    margin-left: unset;
                    padding-right: 0;
                    .overyearlist {
                        margin-left: 0;
                    }
                }
                &-title {
                    font-size: 1.8rem;
                }
            }
            .show-more {
                display: none;
            }
            .year {
                font-size: 6.4rem;
            }
            .swiper-button {
                &-prev {
                    @include position(-3rem, 22.9rem, 2rem, unset);
                }
                &-next {
                    @include position(-3rem, 19rem, 2rem, null);
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-green-initiative {
        .igknighting-change-section,
        .core-initiatives-section,
        .impact-number,
        .upcoming-event {
            padding-inline: var(--container-white-space);
        }
        .preview-swiper {
            .article-title {
                font-size: 5rem;
                padding-inline: var(--container-white-space);
                margin-bottom: var(--space-9);
            }
            .article {
                &-thumbnail {
                    &::after {
                        background: unset;
                    }
                }
            }
            .swiper-button {
                &-prev {
                    @include position(unset, 24rem, 2rem, unset);
                }
                &-next {
                    @include position(unset, 20.1rem, 2rem, null);
                }
            }
        }
        .swiper {
            &-pagination {
                bottom: var(--space-4);
            }
        }
        .swiper-button {
            &-next,
            &-prev {
                display: flex;
                width: 3.9rem;
                height: 3.5rem;
                &.swiper-button-disabled {
                    opacity: unset;
                    background: var(--primary-200);
                    &::after {
                        color: var(--secondary-800);
                        font-size: .8rem;
                    }
                }
                &::after {
                    font-size: .8rem;
                }
            }
            &-next {
                border-radius: 0 0.5rem 0.5rem 0;
            }
            &-prev {
                border-radius: 0.5rem 0 0 0.5rem;
            }
        }
        .igknighting-change-section {
            padding-block: var(--space-12);
            .waf-body {
                gap: var(--space-3);
                @include flex-config(flex, null, null, center);
            }
            .knighting-change-image {
                margin-left: auto;
            }
            .thumbnail {
                width: 40%;
                margin-bottom: 0;
            }
            .content {
                width: 60%;
            }
            .title {
                font-size: 3.2rem;
            }
            .sub-title,
            .text {
                font-size: 1.6rem;
            }
        }
        .core-initiatives-section {
            padding-bottom: var(--space-12);
            .article {
                &-list {
                    display: flex;
                    gap: var(--space-3);
                    overflow-x: unset;
                    flex-wrap: nowrap;
                    @include custom-scroll();
                    @include listing-card(overlay);
                }
                &-item {
                    position: relative;
                    width: calc((100% - var(--space-9)) / 4);
                }
                &-content {
                    z-index: 2;
                    flex-wrap: wrap;
                    display: flex;
                }
                &-thumbnail {
                    overflow: hidden;
                }
            }
            .core-image {
                transition: 1s ease;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        .impact-number {
            padding-bottom: var(--space-8);
            .impact {
                &-content {
                    color: var(--neutral-50);
                    align-items: center;
                    position: relative;
                }
                &-wrap {
                    display: flex;
                    align-items: center;
                }
                &-wrapper {
                    flex-wrap: unset;
                    &::before {
                        content: unset;
                    }
                }
                &-item {
                    margin-bottom: var(--space-10);
                }
            }
            .count {
                font-size: 5.2rem;
            }
            .subtext {
                font-size: 1.4rem;
            }
            .text {
                font-size: 1.4rem;
            }
            .first,
            .third {
                .impact-item {
                    &:nth-child(1) {
                        width: 50%;
                        &:before {
                            content: unset;
                        }
                        &::after {
                            content: "";
                            height: 5rem;
                            width: 0.1rem;
                            background-color: var(--secondary-800);
                            position: absolute;
                            left: 100%;
                        }
                    }
                    &:nth-child(2) {
                        &::after {
                            position: absolute;
                            left: 100%;
                        }
                    }
                    .impact-image {
                        width: 6.4rem;
                        height: 6.4rem;
                    }
                    &:nth-child(3) {
                        padding-left: 0;
                    }
                }
            }
            .second {
                padding-inline: var(--space-16);
                .impact-image {
                    width: 10rem;
                    height: 10rem;
                }
                .impact-item {
                    &:nth-child(1) {
                        &::after {
                            position: absolute;
                            left: 100%;
                        }
                    }
                    .impact-wrap {
                        flex-direction: row;
                        text-align: unset;
                    }
                }
                .count {
                    font-size: 6.4rem;
                }
                .subtext {
                    margin-top: var(--space-1-neg);
                }
            }
            .third.impact-wrapper {
                .impact-item {
                    &:nth-child(2),
                    &:nth-child(3) {
                        margin-bottom: var(--space-10);
                        padding-left: 0;
                    }
                }
            }
            .records {
                color: var(--neutral-50);
            }
        }
        .upcoming-event {
            .article {
                &-list {
                    flex-direction: row;
                }
                &-item {
                    width: calc(100% - var(--space-8) / 2);
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }
                &-title {
                    font-size: 1.6rem;
                    padding-bottom: var(--space-4);
                }
            }
        }
    }
}